import React from "react";
import BasicInfo from "../../components/ProfileComponents/BasicInfo";
import ProfileInfo from "../../components/ProfileComponents/ProfileInfo";
import AboutMe from "../../components/ProfileComponents/AboutMe";
import "./ProfileContainer.css";
import TechnicalSkills from "../../components/ProfileComponents/TechnicalSkills";
import PreferedArea from "../../components/ProfileComponents/PreferedArea";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useAuth } from "../../contexts/AuthContext";

const ProfileContainer = () => {
  const { userProfileData } = useAuth();

  const basicInfo = {
    name: userProfileData.f_name && userProfileData.l_name ? `${userProfileData.f_name} ${userProfileData.l_name}`:"Add your name",
    img_url: userProfileData.front_picture_link
  };

  const profileInfo = {
    email: userProfileData.email ? userProfileData.email : "Add your email",
    phone_number: userProfileData.phone ? userProfileData.phone : "Add your phone number",
    degree: userProfileData.degree_id === 0 ? "IT" : "ITM",
    job_status: userProfileData.hired === 1 ? "Hired" : "Pending",
  };

  const skills =userProfileData.skills ? JSON.parse(userProfileData.skills) : [];

  const preferedArea = userProfileData.preferred_area ? JSON.parse(userProfileData.preferred_area) : [];

  const aboutMe = userProfileData.profile ? userProfileData.profile : "Say something meaningful about you";

  const cv = userProfileData.cv_link ? userProfileData.cv_link : '';

  const linkedin = userProfileData.LinkedIn_link ? userProfileData.LinkedIn_link : '';

  return (
    <div
      className="container"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <Card
        elevation={10}
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          maxWidth: "900px",
          margin: "auto",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        <CardContent>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <BasicInfo basicInfo={basicInfo} />
            </div>
            <div className="col-12 col-md-6">
              <ProfileInfo profileInfo={profileInfo} cv={cv} linkedin={linkedin} />
            </div>
          </div>
          <div className="container col-md-11" style={{ marginTop: "20px" }}>
            <AboutMe aboutMe={aboutMe ? aboutMe : ""} />
          </div>
          <div className="container col-md-11" style={{ marginTop: "20px" }}>
            <TechnicalSkills skills={skills} />
          </div>
          <div className="container col-md-11" style={{ marginTop: "20px" }}>
            <PreferedArea preferedArea={preferedArea } />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProfileContainer;
