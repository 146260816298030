import { useEffect } from 'react';
import "driver.js/dist/driver.css";
import { driver } from "driver.js";
import { shouldStartTour, markTourAsShown } from "../../services/TourService";

export const StudentsTour = () => {
    const tourName = "student";

    useEffect(() => {
        // Start the "student tour" only if it hasn't been shown before
        if (shouldStartTour(tourName)) {
            startTour();

            // Mark the "student tour" as shown after starting it
            markTourAsShown(tourName);
        }
    }, []);

    const startTour = () => {
        const config = {
            steps: [
                {
                    element: '.ais-SearchBox',
                    popover: { title: 'Search FIT 19 Undergraduates Easily', description: 'You can search students using their First Name, Last Name, Tech Skills, Job Positions of Interest and University Index.', side: "left", align: 'start' 
                }
                },
                {
                    element: '.MuiButtonBase-root',
                    popover: {
                        title: 'Easily Filter using Job Roles', description: 'Click the Filters button to open the menu to filter students by their preferred job roles', side: "left", align: 'start',
                    },
                    onHighlightStarted: function (element) {
                        document.querySelector('.MuiButtonBase-root').click();
                    },
                },
                {
                    element: '.MuiList-root',
                    popover: {
                        title: 'Job Role Filtering', description: 'Here you can see all the job roles that FIT 19 students have interests in. You can select multiple roles to filter as well.', side: "left", align: 'start',
                    },
                },
                {
                    element: '.student-card',
                    popover: {
                        title: 'View a Student', description: 'Click the card to see more information of the student including CV, LinkedIn and other contact details.', side: "left", align: 'start',
                    },
                },
            ],
            allowClose: true,
        };

        const tourInstance = driver(config);

        // Start the tour directly
        tourInstance.drive();
    };
};