import React, { useState } from "react";
import "./LoadingContainer.css";
const LoadingContainer = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className={`loading-container d-flex vh-100 vw-100 align-items-center justify-content-center ${
        isLoading ? "" : "hidden"
      }`}
    >
      <img src="./ffcLogo.png" alt="FFC Logo" className="logo glowing-logo" />
    </div>
  );
};

export default LoadingContainer;
