import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
} from "../services/TokenService";
import axios from "../services/ApiService";
import LoadingContainer from "../containers/LoadingContainer/LoadingContainer";
import { toast } from 'react-toastify';

const AuthContext = createContext({
  userData: null,
  role: null,
  userProfileData: null,
  handleUser: () => {},
  logout: () => {},
  updateUserData: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    window.addEventListener("userChange", handleUser);
    // window.addEventListener('userLogout', logout);

    // document.getElementById('loader') && (document.getElementById('loader').style.display = 'none');
    initApp();
  }, []);
  const initApp = async () => {
    const token = getRefreshToken();

    if (token) {
      try {
        const res = await axios.post("/user/refresh_token", {
          refresh_token: token,
        });
        const { access_token } = res.data.data;
        setAccessToken(access_token);
      } catch (err) {
        removeRefreshToken();
        toast.success('User password reset Successfully!');
      }
    }
    setIsLoading(false);
  };
 
  const handleUser = () => {
    const token = getAccessToken();
    if (token) {
      const user = jwtDecode(token);
      setUserData(user);
      setUserProfileData(user.userData)
      setRole(user.role);
    } else {
      setUserData(null);
      setRole(null);
    }
  };

  const logout = () => {
    setUserData(null);
    setRole(null);
    setUserProfileData(null);
    removeAccessToken();
    removeRefreshToken();
  };

  const updateUserData = (formData) => {
    setUserProfileData(formData);
  }

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        role,
        userProfileData,
        handleUser,
        logout,
        updateUserData,
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthProvider;
