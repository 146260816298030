import React from "react";
import LoginForm from "../../components/LoginComponents/LoginForm";
import SideDetailsCard from "../../components/LoginComponents/SideDetailsCard";
import Header from "../../components/shared/header/Header";
import './LoginContainer.css'

const LoginContainer = () => {
  return (
    <div className="min-vh-100">
    <Header/>
    <div className="pink-back"/>
      <div className="login-container">
        <div className="row">
          <div
            className="col-md-6 d-md-block d-none "
            style={{ backgroundColor: "#F8F2FD", zIndex:100}}
          >
            <SideDetailsCard />
          </div>
          <div className="col-md-6 col-sm-12">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
