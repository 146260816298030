import "./App.css";
import { Route, Routes} from "react-router-dom";
import RouteMapping from "./Routes";
import { useAuth } from "./contexts/AuthContext";
import ErrorPage from './ErrorPage.jsx'

function App() {
  const renderRoutes = (RouteMapping, key) =>(
    RouteMapping.map((route) => (
      <Route
        key={`${key}/${route.path}`}
        path={route.path}
        {...route.routeProps}
        element={
          route.guard ? (
            <route.guard {...route.guardProps} component={route.component} />
          ) : (
            route.component
          )
        }
      >
        {route.subRoutes &&
          renderRoutes(route.subRoutes, `${key}/${route.path}`)}
      </Route>
    ))
  );
  return <Routes>{renderRoutes(RouteMapping, "root")}
  <Route path='*' element={<ErrorPage />} />
  </Routes>;
}

export default App;
