import React from "react";
import Navbar from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import EsouvenirComponent from "../../components/SouvenirComponent/Esouvenir";

const SouvenirContainer = () => {
  return (
    <div>
      <Navbar url={"/souvenir"} />
      <EsouvenirComponent />
      <Footer />
    </div>
  );
};

export default SouvenirContainer;
