import React, {useState} from 'react';
import {Box, Modal,Button, Fade, Grid, TextField } from '@mui/material'
import './AddStudentModal.css';
import { toast } from 'react-toastify';
import { createUser } from '../../services/StudentService';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35%',
  maxHeight: '100vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};


const OpenModal = ({ showModal, setShowModal }) => {
    const [indexValue, setIndexValue] = useState('');

    const addUser = async() => {
      const hashedIndexValue = indexValue;
      const userObject = {
        "index": indexValue,
        "password" : hashedIndexValue
      }

      try {
        await createUser(userObject);
        toast.success('Student added Successfully!');
        setIndexValue('');
        setShowModal(false);
      } catch (err) {
        toast.error('Student not updated!');
      }
    };

    const handleClose = () => {
      setIndexValue('');
      setShowModal(false);
    };

    return (

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showModal}
          onClose={setShowModal}
        >
          <Fade in={showModal}>
            <Box sx={style}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <h5>Add User</h5>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Index"
                  value={indexValue}
                  onChange={(e) => setIndexValue(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={9}>
              <Button type="submit" variant="contained" style={{ alignItems: 'center' }} fullWidth onClick={addUser}>
                Add
              </Button>
              </Grid>
              <Grid item sm={12} md={3}>
                <Button variant="contained" color='error' style={{ alignItems: 'center' }} fullWidth onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
            </Box>
          </Fade>
        </Modal>


    );
  };
  
  export default OpenModal;