import React from "react";
import "./ButtonComponent.css";

const ButtonComponent = ({ buttonText, handleButtonClick,type,customStyle }) => {

  return (
    <div>
      <button className="common-btn" onClick={handleButtonClick} type={type} style={customStyle}>{buttonText}</button>
    </div>
  );
};

export default ButtonComponent;
