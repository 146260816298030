import React from "react";

const SideDetailsCard = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <img src="/loginVector.png" className="img-fluid" alt="Login Vector" style={{width:'85%'}}/>
    </div>
  );
};

export default SideDetailsCard;
