import React, { useEffect, useState } from "react";
import PieChart from "../../components/ChartComponents/PieChart";
import BarChart from "../../components/ChartComponents/BarChart";
import { getHiredCount, getHiredCountByCompany, getHiredCountByDegree } from "../../services/UserService";
import { toast } from 'react-toastify';

const DashboardContainer = () => {
  const [hiredCount, setHiredCount] = useState();
  const [notHiredCount, setNotHiredCount] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [itmHiredCount, setItmHireCount] = useState();
  const [itHiredCount, setItHireCount] = useState();
  const [itmUnhiredCount, setItmUnhiredCount] = useState();
  const [itUnhiredCount, setItUnhireCount] = useState();

  const hiringCount = async () => {
    try {
      const res = await getHiredCount();
      setHiredCount(res.data.data[1].count);
      setNotHiredCount(res.data.data[0].count);
    } catch (error) {
      toast.error("Something went wrong")
    }
  };

  const hiringCountByCompany = async () => {
    try {
      const res = await getHiredCountByCompany();
      setCompanyData(res.data.data);
    } catch (error) {
      toast.error("Something went wrong")
    }

  };

  const hiringCountByDegree = async () => {
    try {
      const res = await getHiredCountByDegree();
      setItmHireCount(res.data.data[1].hiredCount);
      setItmUnhiredCount(res.data.data[1].unHiredCount);
      setItHireCount(res.data.data[0].hiredCount);
      setItUnhireCount(res.data.data[0].unHiredCount);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    hiringCount();
    hiringCountByCompany();
    hiringCountByDegree();
  }, []);

  const hireLabels = ["Unhired", "Hired"];
  const hireData = [notHiredCount, hiredCount];

  const companyNames = companyData.map((data) => data.company_name);
  const barDataSet = companyData.map((data) => data.count);

  const colorList = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
    "#9edae5",
  ];

  const barData = {
    labels: companyNames,
    datasets: [
      {
        label: 'Count',
        backgroundColor: colorList,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,
        data: barDataSet
      }
    ]
  }

  const barOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'right'
      },
      title: {
        display: true,
        text: 'Company-wise Hiring Overview',
      },
    },
    aspectRatio: 3,
    responsive: true,
    maintainAspectRatio: false
  };

  const stackOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Degree-wise Hiring Overview',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ['IT', 'ITM'];

  const degreeData = {
    labels,
    datasets: [
      {
        label: 'Hired',
        data: [itHiredCount, itmHiredCount],
        backgroundColor: 'rgb(54, 162, 235)',
      },
      {
        label: 'Unhired',
        data: [itUnhiredCount, itmUnhiredCount],
        backgroundColor: 'rgb(255, 99, 132)',
      }
    ],
  };

  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-md-2 g-3">
        <div className="col">
          <div className="card shadow pt-1">
            <div className="card-body">
              <PieChart labels={hireLabels} pieData={hireData} />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card shadow">
            <div className="card-body">
              <BarChart options={stackOptions} data={degreeData} />
            </div>
          </div>
        </div>
      </div>
      <div className="col mt-4 mb-4">
        <div className="card shadow">
          <div className="card-body p-5">
            <BarChart data={barData} options={barOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
