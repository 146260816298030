import CommonCompanyContainer from "./containers/CommonCompanyContainer/CommonCompanyContainer";
import HomeContainer from "./containers/HomeContainer/HomeContainer";
import SouvenirContainer from "./containers/SouvenirConatainer/SouvenirContainer";
import StudentsContainer from "./containers/StudentsContainer/StudentsContainer";
import NonGuardedRoute from "./route-guards/NonGuardedRoute";
import RoleGuardedRoute from "./route-guards/RoleGuardedRoute";
import LoginContainer from "./containers/LoginContainer/LoginContainer";
import DrawerComponent from "./components/DrawerComponent/DrawerComponent";
const RouteMapping = [
  {
    path: "/",
    guard: NonGuardedRoute,
    component: <HomeContainer />,
  },
  {
    path: "students",
    guard: NonGuardedRoute,
    component: <StudentsContainer />,
  },
  {
    path: "companies",
    guard: NonGuardedRoute,
    component: <CommonCompanyContainer />,
  },
  {
    path: "souvenir",
    guard: NonGuardedRoute,
    component: <SouvenirContainer />,
  },
  {
    path: "login",
    guard: NonGuardedRoute,
    component: <LoginContainer />,
  },
  {
    path: "dashboard-student",
    guard: RoleGuardedRoute,
    guardProps: {
      roleType: "student",
    },
    component: <DrawerComponent />,
  },
  {
    path: "dashboard-admin",
    guard: RoleGuardedRoute,
    guardProps: {
      roleType: "admin",
    },
    component: <DrawerComponent />,
  },
];

export default RouteMapping;
