import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import './DrawerComponent.css'
import StudentPageAdmin from '../../containers/StudentContainer/StudentPage';
import CompanyContainer from "../../containers/CompanyContainer/CompanyContainer";
import DashboardContainer from "../../containers/DashboardContainer/DashboardContainer";
import PasswordResetContainer from "../../containers/StudentPasswordResetContainer/PasswordReset"
import { useAuth } from "../../contexts/AuthContext";
import ProfileContainer from "../../containers/ProfileContainer/ProfileContainer";
import { toast } from "react-toastify";
import { updateUser } from "../../services/UserService";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const { role, logout, userProfileData } = useAuth();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(role === "student" ? "My Profile" : "Dashboard");
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(userProfileData);

  const handleUpdate = async (formData) => {
    await updateUser(formData.uni_index, formData)
      .then((res) => {
        toast.success("Details updated Successfully!");
      })
      .catch((err) => {
        toast.error("Details not updated!");
      });
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleListItemClick = (text) => {
    setSelectedItem(text);
    if (text === "Edit Profile") {
      setOpenModal(true); // Set openModal to true when "Edit Profile" is clicked
    } else {
      handleDrawerClose();
    }
  };

  const renderComponent = () => {
    switch (selectedItem) {
      case "Dashboard":
        return <DashboardContainer />;
      case "Companies":
        return <CompanyContainer />;
      case "Students":
        return <StudentPageAdmin />
      case "My Profile":
        return <ProfileContainer />;
      case "Edit Profile":
        return <ProfileContainer />;
      case "Change Password":
        return <PasswordResetContainer />;
      default:
        return null;
    }
  };

  const drawerAdminItems = [
    { text: "Dashboard", icon: <DashboardIcon /> },
    { text: "Companies", icon: <BusinessIcon /> },
    { text: "Students", icon: <PeopleIcon /> },
  ];
  const drawerStudentItems = [
    { text: "My Profile", icon: <DashboardIcon /> },
    { text: "Edit Profile", icon: <BusinessIcon /> },
    { text: "Change Password", icon: <PeopleIcon /> },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem key={"Home"} disablePadding onClick={() => navigate('/')}>
          <ListItemButton>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        {role === "student" &&
          drawerStudentItems.map((item, index) => (
            <ListItem
              key={item.text}
              disablePadding
              selected={selectedItem === item.text}
              onClick={() => handleListItemClick(item.text)}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        {role === "admin" &&
          drawerAdminItems.map((item, index) => (
            <ListItem
              key={item.text}
              disablePadding
              selected={selectedItem === item.text}
              onClick={() => handleListItemClick(item.text)}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton color="error" onClick={logout} sx={{ ml: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Ensure vertical center alignment */}
              <LogoutIcon />
              <Typography variant="body1" sx={{ ml: 1, color: 'red' }}>Logout</Typography> {/* Adjust typography variant */}
            </Box>
          </IconButton>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {renderComponent()}
      </Box>
      <EditProfileModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleUpdate}
      />
    </Box>
  );
}

export default ResponsiveDrawer;
