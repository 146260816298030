import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from 'react-chartjs-2';

ChartJS.register(Title, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const BarChart = ({ data, options }) => {
  BarChart.propTypes = {
    data: PropTypes.object,
    options: PropTypes.object
  };

  return (
    <div>
      <Bar data={data} options={options} style={{ height: '300px', width: '100%' }} />
    </div>
  );
};

export default BarChart;
