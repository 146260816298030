import { Button } from '@mui/material';
import React, { useState } from 'react'
import AddCompanyModal from '../../components/AddCompanyModal/ModalComponent';
import CompanyTable from '../../components/CompanyTable/CompanyTable';
import { saveCompany } from '../../services/CompanyService';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';

const CompanyContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({ company_name: '', logo_link: '', username: '', password: '', email: '', sponsor_id: '', description: '', website_link: '' });

  const handleSubmit = async (formData) => {
    await saveCompany(formData).then((res) => {
      toast.success('Company saved Successfully!')
    }).catch((err) => {
      toast.success('Company not saved.')
    })
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10pt' }}>
        <Button color='success' variant="contained" onClick={() => setOpenModal(true)} >
          <AddIcon />Add Company
        </Button>
      </div>
      <CompanyTable />
      <AddCompanyModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default CompanyContainer