import React from "react";
import Chip from "@mui/material/Chip";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

const ProfileInfo = ({profileInfo,linkedin,cv}) => {

  const info = [
    { title: "Email", value: profileInfo.email },
    { title: "Phone Number", value: profileInfo.phone_number },
    { title: "Degree", value: profileInfo.degree },
    { title: "Job Status", value: profileInfo.job_status },
  ];

  const formatLinkedInURL = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };
  return (
    <div className="about-text">
      <h3 className="dark-color">Basic Data</h3>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="row about-list">
            {info.map((item, index) => (
              <div className="media" key={index}>
                <label>{item.title}</label>
                <p>{item.value}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="custom-chip">
            <Link to={cv} target="_blank" rel="noopener noreferrer">
            <Chip
              label="CV"
              color="primary"
              size="large"
              onClick={() => {
              }}
              sx={{
                color: 'white',
                ':hover': {
                  transform: 'scale(1.1)', 
                  transition: 'transform 0.3s' 
                }
              }}
            />
            </Link>
          </div>
          <a
            href={formatLinkedInURL(linkedin)}
            target="_blank"
            rel="noopener noreferrer"
          >
          <div className="custom-icon">
            <LinkedInIcon sx={{ fontSize: 40,':hover': {
                  transform: 'scale(1.1)', 
                  transition: 'transform 0.3s' 
                } }} />
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default ProfileInfo;
