import React, { useState } from "react";
import InputField from "./InputField";
import { setAccessToken, setRefreshToken } from "../../services/TokenService";
import { login } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import LoadingContainer from "../../containers/LoadingContainer/LoadingContainer";
import "./InputField.css";
import { toast } from "react-toastify";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    index: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await login(payload);
      const { access_token, refresh_token} = res.data;
      const userRole = res.data.data[0].role;
      setRefreshToken(refresh_token);
      setAccessToken(access_token);
      setTimeout(() => {
        if (userRole === "student") {
          navigate("/dashboard-student");
        } else if (userRole === "admin") {
          navigate("/dashboard-admin");
        }
      }, 1000);
    } catch (err) {
      toast.error("Login Failed");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      {loading && (
        <LoadingContainer isLoading={loading} setIsLoading={setLoading} />
      )}
      {!loading && (
        <div className="container d-flex flex-column h-100 justify-content-center align-items-center w-100">
          <img
            src="/ffcLogo.png"
            className="img-fluid"
            style={{ width: "43%", marginBottom: "9%" }}
            alt="fit fc logo"
          />
          <div className="text-left " style={{ width: "80%" }}>
            <p
              style={{
                color: "rgba(95, 34, 148, 1)",
                fontWeight: 700,
                marginBottom: "32px",
                fontSize:'24px'
              }}
            >
              Student Login
            </p>
            <form onSubmit={(e) => handleLogin(e)}>
              <InputField
                label="Index"
                placeHolder="Enter Index"
                value={payload.index}
                onChange={(e) =>
                  setPayload({ ...payload, index: e.target.value })
                }
                type="text"
              />
              <InputField
                label="Password"
                value={payload.password}
                placeHolder="Enter Password"
                onChange={(e) =>
                  setPayload({ ...payload, password: e.target.value })
                }
                type="password"
              />
              <ButtonComponent buttonText="Login" type="submit"/>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginForm;
