import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const app = initializeApp({
    apiKey: "AIzaSyCDItMWqGMh4lqpj3IQip0FfqViIHmp72k",
    authDomain: "fit-career-fair-2024.firebaseapp.com",
    projectId: "fit-career-fair-2024",
    storageBucket: "fit-career-fair-2024.appspot.com",
    messagingSenderId: "600813804743",
    appId: "1:600813804743:web:7000e89202bfcf92d932e2",
    measurementId: "G-8T8K88R81V"
})

const storage = getStorage(app);
export default storage;