import ax from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "./TokenService";
import { toast } from "react-toastify";

const axios = ax.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const config = err.config;
    if (err.response) {
      if (err.response.status === 401 && !config._retry) {
        config._retry = true;

        try {
          const res = await axios.post("/user/refresh_token", {
            refresh_token: getRefreshToken(),
          });

          const { access_token } = res.data.data;
          setAccessToken(access_token);

          return axios(config);
        } catch (_err) {
          toast.error("login expired. please login as usual.");
          return Promise.reject(_err);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default axios;
