import React, { useState } from 'react';
import { useRefinementList,useClearRefinements } from 'react-instantsearch';
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function CustomRefinementList({ attribute }) {
    // For clearing the applied filters.
    // Using alias because conflicting with `useRefinementList` hook.
    const { refine: clearRefine } = useClearRefinements();
    
    const { items, refine } = useRefinementList({ attribute, sortBy: ['name:asc'] });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckboxChange = (value) => {
        refine(value); // Use the refine function from useRefinementList to filter the items.
    };

    const clearAll = () => {
        clearRefine();
    };

    return (
        <>
            <IconButton
                disableRipple
                onClick={handleClick}
                sx={{
                    fontSize: "1.5rem",
                    color: "#51127F",
                    boxShadow: "none",
                    "&:hover": {
                        transition: "all .2s ease-in-out",
                        transform: "scale(1.05)",
                        backgroundColor: "transparent",
                    },
                }}
            >
                <FilterAltIcon />
                <Typography
                    sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}
                >
                    Filter
                </Typography>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: "350px",
                        width: "300px",
                        overflow: "auto",
                        marginTop: "15px",
                        borderRadius: "10px",
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{ textAlign: "right", paddingRight: "20px" }}>
                    {/* Clear button */}
                    <IconButton
                        disableRipple
                        onClick={clearAll}
                        sx={{
                            fontSize: "0.1rem",
                            boxShadow: "none",
                            color: "#5F2294",
                            "&:hover": {
                                transition: "all .2s ease-in-out",
                                transform: "scale(1.05)",
                                backgroundColor: "transparent",
                            },
                            padding: 0,
                            margin: 0,
                            paddingLeft: "10px !important",
                        }}
                    >
                        <HighlightOffIcon sx={{ fontSize: "20px" }} />
                        <Typography
                            component="span"
                            sx={{
                                marginLeft: "5px",
                                fontStyle: "italic",
                                fontSize: "15px",
                                fontWeight: "bold",
                                padding: 0,
                                margin: 0,
                            }}
                        >
                            Clear
                        </Typography>
                    </IconButton>
                </div>
                <div className="checkboxes-container">
                    {items.map((item, index) => (
                        <MenuItem key={index}>
                            <div className="two-column styled-input-container styled-input--rounded">
                                <div className="styled-input-single">
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        checked={item.isRefined}
                                        onChange={() => handleCheckboxChange(item.value)}
                                    />
                                    <label htmlFor={`checkbox-${index}`}>{item.label} ({item.count})</label>
                                </div>
                            </div>
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}

export default CustomRefinementList;
