import React, { useEffect } from "react";
import Home from "../../components/homepage/Home";
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeContainer = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 130,
      easing: 'Ease-in-out-back'
    });
    
  }, []);

  return (
    <div>
      <Header url={"/"}/>
      <Home/>
      <Footer/>
    </div>
  );
};

export default HomeContainer;
