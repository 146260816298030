import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Modal, Fade, Button, TextField, Grid } from '@mui/material'
import { getAllSkills } from '../../services/SkillServices';
import { getAllAreas } from '../../services/PreferredAreaServices';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllDegree } from '../../services/DegreeService';
import { getAllCompany } from '../../services/CompanyService';
import storage from "../../firebaseConfig"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Paper from '@mui/material/Paper';
import { toast } from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '../../contexts/AuthContext';
import imageCompression from 'browser-image-compression';
import LinearProgress from '@mui/material/LinearProgress';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const compressionOptions = {
    maxSizeMB: 3,
    useWebWorkers: true,
};

const EditProfileModal = ({ open, onClose, formData, setFormData, onSubmit }) => {
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [degree, setDegree] = useState([]);
    const [selectedDegree, setSelectedDegree] = useState(null);
    const [hired, setHired] = useState(null);
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [frontImgName, setFrontImgName] = useState("");
    const [sideImgName, setSideImgName] = useState("");
    const [cvName, setCVName] = useState("");
    const { updateUserData } = useAuth();

    const [errors, setErrors] = useState({});
    const formErrors = {};

    const [loading, setLoading] = useState({
        frontpic: false,
        sidepic: false,
        cv: false,
        frontpicDisabled: false,
        sidepicDisabled: false,
        cvDisabled: false,
        save: false
    });
    const hiredOptions = [
        { label: 'Available', value: 0 },
        { label: 'hired', value: 1 }
    ];

    const [frontPicProgress, setFrontPicProgress] = useState(0);
    const [sidePicProgress, setSidePicProgress] = useState(0);
    const [cvProgress, setCvProgress] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors(validateUser());
        if (Object.keys(formErrors).length === 0) {
            onSubmit(formData);
            updateUserData(formData);
            onClose();
        } else {
            toast.error("Please check the input fields.")
        }
    };

    const validateUser = () => {
        const phoneRegx = RegExp(/^\d{10}$/);
        const emailRegex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

        (formData.front_picture_link === "" || formData.front_picture_link === null) ? (formErrors.front_picture_link = "Please Upload Front facing Image!") : delete formErrors.front_picture_link;
        (formData.side_picture_link === "" || formData.side_picture_link === null) ? (formErrors.side_picture_link = "Please Upload Side facing Image!") : delete formErrors.side_picture_link;
        (formData.cv_link === "" || formData.cv_link === null) ? (formErrors.cv_link = "Please Upload the CV!") : delete formErrors.cv_link;
        (formData.f_name === "" || formData.f_name === null) ? (formErrors.f_name = "Please enter first name!") : delete formErrors.f_name;
        (formData.l_name === "" || formData.l_name === null) ? (formErrors.l_name = "Please enter last name!") : delete formErrors.l_name;
        (formData.LinkedIn_link !== null) ? delete formErrors.LinkedIn_link : (formErrors.LinkedIn_link = "Please enter linkedin profile!");
        (formData.phone !== null) && phoneRegx.test(formData.phone) ? delete formErrors.phone : (formErrors.phone = "Please enter a valid phone number!");
        (formData.email !== null) && emailRegex.test(formData.email) ? delete formErrors.email : (formErrors.email = "Please enter a valid phone number!");
        (formData.profile === "" || formData.profile === null) ? (formErrors.profile = "Please enter your profile!") : delete formErrors.profile;
        (formData.preferred_area === "[]" || formData.preferred_area === null) ? (formErrors.preferred_area = "Please select preferred areas!") : delete formErrors.preferred_area;
        (formData.skills === "[]" || formData.skills === null) ? (formErrors.skills = "Please select skills!") : delete formErrors.skills;
        return formErrors;
    };

    const handleOnCancel = () => {
        setFormData(" ")
        onClose();
    }

    const handleSkillsChange = (event, newValue) => {
        setSelectedSkills(newValue)
        setFormData({ ...formData, skills: JSON.stringify(newValue) })
    };

    const handleAreaChange = (event, newValue) => {
        setSelectedAreas(newValue)
        setFormData({ ...formData, preferred_area: JSON.stringify(newValue) })
    }

    const handleDegreeChange = (event, newValue) => {
        setSelectedDegree(newValue);
        setFormData({ ...formData, degree_id: newValue.degree_id })
    };

    const handleHiredChange = (event, newValue) => {
        setHired(newValue.value);
        setFormData({ ...formData, hired: newValue.value })
    };

    const handlecompanyChange = (event, newValue) => {
        setSelectedCompany(newValue);
        setFormData({ ...formData, company_id: newValue.company_id })
    }

    const handleFrontImgUpload = async (event) => {
        setLoading(prevState => ({
            ...prevState,
            frontpic: true,
            sidepicDisabled: true,
            cvDisabled: true
        }));
        const frontImg = event.target.files[0];
        setFrontImgName(frontImg.name);

        //compression
        var compressedImage;
        try {
            compressedImage = await imageCompression(frontImg, {
                ...compressionOptions,
                onProgress: (progress) => {
                    setFrontPicProgress(Math.round(progress));
                }
            });
            setFrontPicProgress(90)
        } catch (error) {
            console.error('Image compression error:', error);
        }

        const storageRef = ref(storage, `/profile/${formData.uni_index}/${formData.uni_index}_frontIMG`);

        const uploadTask = uploadBytesResumable(storageRef, compressedImage);

        uploadTask.on(
            "state_changed",
            (snapshot) => { },
            (err) => {
                toast.error("Something went wrong")
                setLoading(prevState => ({
                    ...prevState,
                    frontpic: false,
                    sidepicDisabled: false,
                    cvDisabled: false
                }));
                setFrontPicProgress(0)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setFrontPicProgress(100)
                    setFormData({ ...formData, front_picture_link: url })
                    setLoading(prevState => ({
                        ...prevState,
                        frontpic: false,
                        sidepicDisabled: false,
                        cvDisabled: false
                    }));
                    setFrontPicProgress(0)
                });
            }
        );
    };

    const handleSideImgUpload = async (event) => {
        setLoading(prevState => ({
            ...prevState,
            frontpicDisabled: true,
            sidepic: true,
            cvDisabled: true
        }));
        const sideImg = event.target.files[0];
        setSideImgName(sideImg.name);

        //compression
        var compressedImage;
        try {
            compressedImage = await imageCompression(sideImg, {
                ...compressionOptions,
                onProgress: (progress) => {
                    setSidePicProgress(Math.round(progress));
                }
            });
            setSidePicProgress(90)
        } catch (error) {
            console.error('Image compression error:', error);
        }

        const storageRef = ref(storage, `/profile/${formData.uni_index}/${formData.uni_index}_sideIMG`);

        const uploadTask = uploadBytesResumable(storageRef, compressedImage);

        uploadTask.on(
            "state_changed",
            (snapshot) => { },
            (err) => {
                toast.error("Something went wrong")
                setLoading(prevState => ({
                    ...prevState,
                    frontpicDisabled: false,
                    sidepic: false,
                    cvDisabled: false
                }));
                setSidePicProgress(0)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setSidePicProgress(100)
                    setFormData({ ...formData, side_picture_link: url })
                    setLoading(prevState => ({
                        ...prevState,
                        frontpicDisabled: false,
                        sidepic: false,
                        cvDisabled: false
                    }));
                    setSidePicProgress(0)
                });
            }
        );
    };

    const handleCVUpload = (event) => {
        setLoading(prevState => ({
            ...prevState,
            frontpicDisabled: true,
            sidepicDisabled: true,
            cv: true
        }));
        const CV = event.target.files[0];
        setCVName(CV.name);
        const storageRef = ref(storage, `/profile/${formData.uni_index}/${formData.uni_index}_CV`);

        const uploadTask = uploadBytesResumable(storageRef, CV);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setCvProgress(progress);
            },
            (err) => {
                toast.error("Something went wrong")
                setLoading(prevState => ({
                    ...prevState,
                    frontpicDisabled: false,
                    sidepicDisabled: false,
                    cv: false
                }));
                setCvProgress(0)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setFormData({ ...formData, cv_link: url })
                    setLoading(prevState => ({
                        ...prevState,
                        frontpicDisabled: false,
                        sidepicDisabled: false,
                        cv: false
                    }));
                    setCvProgress(0)
                });
            }
        );
    };

    useEffect(() => {
        getAllSkills().then((res) => {
            const skillArr = res.data.data;
            const allSkills = skillArr.map((skill) => skill.skill_name);
            setSkills(allSkills)
        }).catch((err) => {
            toast.error("Something went wrong")
        })
        getAllAreas().then((res) => {
            const areaArr = res.data.data;
            const allAreas = areaArr.map((area) => area.area_name);
            setAreas(allAreas)
        }).catch((err) => {
            toast.error("Something went wrong")
        })
        getAllDegree().then((res) => {
            setDegree(res.data.data)
            const initialOption = res.data.data.find(option => option.degree_id === formData?.degree_id);
            if (initialOption) {
                setSelectedDegree(initialOption);
            }
        }).catch((err) => {
            toast.error("Something went wrong")
        })
        getAllCompany().then((res) => {
            const compArr = [
                ...res.data.data,
            ];
            setCompany(compArr)
            const initialOption = res.data.data.find(option => option.company_id === formData?.company_id);
            if (initialOption) {
                setSelectedCompany(initialOption);
            }
        }).catch((err) => {
            toast.error("Something went wrong")
        })
        if (formData.skills !== null) {
            setSelectedSkills(JSON.parse(formData.skills))
        }
        if (formData.preferred_area !== null) {
            setSelectedAreas(JSON.parse(formData.preferred_area))
        }
        if (formData.hired !== null) {
            setHired(parseInt(formData.hired, 10))
        }
    }, [])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <Item>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item sm={4} md={6}>
                                        <TextField disabled value={frontImgName} fullWidth
                                            error={errors.front_picture_link}
                                            helperText={errors.front_picture_link ? errors.front_picture_link : null}></TextField>
                                    </Grid>
                                    <Grid item sm={8} md={6}>
                                        <Grid item sm={12}>
                                            <LoadingButton component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={handleFrontImgUpload} loading={loading.frontpic} loadingPosition="start" fullWidth disabled={loading.frontpicDisabled}>
                                                <span>{loading.frontpic ? 'Uploading' : 'Upload Front Image'}</span>
                                                <VisuallyHiddenInput type="file" />
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item sm={12} >
                                            <LinearProgress variant="determinate" value={frontPicProgress} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid item sm={12}>
                            <Item>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item sm={4} md={6}>
                                        <TextField disabled value={sideImgName} fullWidth
                                            error={errors.side_picture_link}
                                            helperText={errors.side_picture_link ? errors.side_picture_link : null}></TextField>
                                    </Grid>
                                    <Grid item sm={8} md={6}>
                                        <Grid item sm={12}>
                                            <LoadingButton component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={handleSideImgUpload} loading={loading.sidepic} loadingPosition="start" fullWidth disabled={loading.sidepicDisabled}>
                                                <span>{loading.sidepic ? 'Uploading' : 'Upload Side Image'}</span>
                                                <VisuallyHiddenInput type="file" />
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <LinearProgress variant="determinate" value={sidePicProgress} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid item sm={12}>
                            <Item>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item sm={4} md={6}>
                                        <TextField disabled value={cvName} fullWidth
                                            error={errors.cv_link}
                                            helperText={errors.cv_link ? errors.cv_link : null}
                                        ></TextField>
                                    </Grid>
                                    <Grid item sm={8} md={6}>
                                        <Grid item sm={12}>
                                            <LoadingButton component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={handleCVUpload} loading={loading.cv} loadingPosition="start" fullWidth disabled={loading.cvDisabled}>
                                                <span>{loading.cv ? 'Uploading' : 'Upload Your CV'}</span>
                                                <VisuallyHiddenInput type="file" />
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <LinearProgress variant="determinate" value={cvProgress} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <TextField
                                label="First Name"
                                value={formData.f_name !== null ? formData.f_name : ''}
                                onChange={(e) => setFormData({ ...formData, f_name: e.target.value })}
                                fullWidth
                                error={errors.f_name}
                                helperText={errors.f_name ? errors.f_name : null}
                            />
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <TextField
                                label="Last Name"
                                value={formData.l_name !== null ? formData.l_name : ''}
                                onChange={(e) => setFormData({ ...formData, l_name: e.target.value })}
                                fullWidth
                                error={errors.l_name}
                                helperText={errors.l_name ? errors.l_name : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Linkedin Link"
                                value={formData.LinkedIn_link !== null ? formData.LinkedIn_link : ''}
                                onChange={(e) => setFormData({ ...formData, LinkedIn_link: e.target.value })}
                                fullWidth
                                error={errors.LinkedIn_link}
                                helperText={errors.LinkedIn_link ? errors.LinkedIn_link : null}
                            />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <TextField
                                label="Mobile Number"
                                value={formData.phone !== null ? formData.phone : ''}
                                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                fullWidth
                                error={errors.phone}
                                helperText={errors.phone ? errors.phone : null}
                            />
                        </Grid>
                        <Grid item sm={12} md={8}>
                            <TextField
                                label="Email"
                                value={formData.email !== null ? formData.email : ''}
                                type="email"
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                fullWidth
                                error={errors.email}
                                helperText={errors.email ? errors.email : null}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                value={selectedDegree}
                                onChange={handleDegreeChange}
                                options={degree}
                                getOptionLabel={(option) => option.degree_name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select the Degree" />
                                )}
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Autocomplete
                                value={hiredOptions.find(option => option.value === hired)}
                                options={hiredOptions}
                                onChange={handleHiredChange}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField {...params} label="Are you available or hired?" />
                                )}
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Autocomplete
                                value={selectedCompany}
                                onChange={handlecompanyChange}
                                options={company}
                                getOptionLabel={(option) => option.company_name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select the selected company" />
                                )}
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField
                                label="Profile / Objective"
                                value={formData.profile !== null ? formData.profile : ''}
                                onChange={(e) => setFormData({ ...formData, profile: e.target.value })}
                                fullWidth
                                error={errors.profile}
                                helperText={errors.profile ? errors.profile : null}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                filterSelectedOptions
                                freeSolo
                                options={skills}
                                getOptionLabel={(option) => option}
                                value={selectedSkills}
                                onChange={handleSkillsChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Technical Skills"
                                        placeholder="Technical Skills"
                                        error={errors.skills}
                                        helperText={errors.skills ? errors.skills : null}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (e.target.value.trim() !== '') {
                                                    handleSkillsChange([...selectedSkills, e.target.value.trim()]);
                                                    e.target.value = '';
                                                }
                                            }
                                        }}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                filterSelectedOptions
                                options={areas}
                                getOptionLabel={(option) => option}
                                value={selectedAreas}
                                onChange={handleAreaChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Preferred Areas"
                                        placeholder="Preferred Areas"
                                        error={errors.preferred_area}
                                        helperText={errors.preferred_area ? errors.preferred_area : null}
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={12} md={9}>
                            <LoadingButton type="submit" component="label" variant="contained" style={{ alignItems: 'center' }} fullWidth onClick={handleSubmit} loading={loading.save} loadingPosition="start" disabled={loading.frontpic || loading.sidepic || loading.cv}>
                                <span>{loading.save ? 'Saving' : 'Save'}</span>
                            </LoadingButton>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <Button variant="contained" color='error' style={{ alignItems: 'center' }} fullWidth onClick={handleOnCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};

export default EditProfileModal;