import React from "react";
import x99 from "../../assets/CompanyLogos/99x.png";
import DirectFN from "../../assets/CompanyLogos/directfn.png";
import codegen from "../../assets/CompanyLogos/codegen.png";
import ifs from "../../assets/CompanyLogos/ifs.png";
import ws02 from "../../assets/CompanyLogos/wso2.png";
import sysco from "../../assets/CompanyLogos/sysco-labs.png";
import isa from "../../assets/CompanyLogos/isa.png";
import gtn from "../../assets/CompanyLogos/gtn.png";
import aayu from "../../assets/CompanyLogos/aayu.png";
import "../../containers/CommonCompanyContainer/CompaniesStyles.css";
import cambio from "../../assets/CompanyLogos/cambio.png";
import virtusa from "../../assets/CompanyLogos/virtusa.png";
import kapruka from "../../assets/CompanyLogos/kapruka.png";
import inivos from "../../assets/CompanyLogos/inivos.png";
import ilabs from "../../assets/CompanyLogos/incubatelabs.png";

function DesignBox(props) {
  const handleRedirect = () => {
    window.open(props.link, "_blank");
  };

  return (
    <div
      className="designBoxSmall"
      onClick={handleRedirect}
      style={{ cursor: "pointer" }}
    >
      <img
        className="companyLogoSmall object-fit-cover"
        src={props.companyLogo}
        alt="Company Logo"
      />
      <p className="company-name-small">{props.companyName}</p>
      {props.partnershipStatus && (
        <p className="partnership">{props.partnershipStatus}</p>
      )}
    </div>
  );
}

function WidgetSmall() {
  return (
    <div>
      <div className="d-flex flex-wrap justify-content-center gap-2">
        <DesignBox
          companyLogo={ifs}
          companyName="IFS"
          partnershipStatus="Technology Partner"
          link="https://www.ifs.com/"
        />
        <DesignBox
          companyLogo={codegen}
          companyName="CodeGen International"
          partnershipStatus="Co Partner"
          link="https://codegen.co.uk/"
        />
      </div>

      <div className="d-flex flex-wrap justify-content-center gap-2 mt-2">
        <DesignBox
          companyLogo={aayu}
          companyName="Aayu Technologies"
          link="https://aayutechnologies.com/"
        />
        <DesignBox
          companyLogo={cambio}
          companyName="CAMBIO"
          link="https://www.cambio.lk/"
        />
        <DesignBox
          companyLogo={DirectFN}
          companyName="DirectFN"
          link="http://www.directfn.com/"
        />
        <DesignBox
          companyLogo={gtn}
          companyName="GTN"
          link="https://gtngroup.com/asia/about-us/locations-srilanka/"
        />
        <DesignBox
          companyLogo={ilabs}
          companyName="iLabs"
          link="https://www.ilabs.lk/"
        />
        <DesignBox
          companyLogo={inivos}
          companyName="Inivos"
          link="https://www.inivosglobal.com/"
        />
        <DesignBox
          companyLogo={isa}
          companyName="ISA"
          link="https://accelaero.com/company"
        />
        <DesignBox
          companyLogo={kapruka}
          companyName="Kapruka"
          link="https://blog.kapruka.com/"
        />
        <DesignBox companyLogo={x99} companyName="99x" link="https://99x.io/" />
        <DesignBox
          companyLogo={sysco}
          companyName="Sysco Labs"
          link="https://syscolabs.lk/"
        />
        <DesignBox
          companyLogo={virtusa}
          companyName="Virtusa"
          link="https://www.virtusa.com/"
        />
        <DesignBox
          companyLogo={ws02}
          companyName="WSO2"
          link="https://wso2.com/"
        />
      </div>
    </div>
  );
}

export default WidgetSmall;
