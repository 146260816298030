import React from "react";
import "./ESouvenir.css";
import { Link } from "react-router-dom";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

function Esouvenir() {
  return (
    <div className="container">
      <div className="row justify-content-start py-4 min-vh-100">
        <Link
          to="http://intecs.itfac.mrt.ac.lk/cf2023-assets/CF2023_Souvenir.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonComponent buttonText="Direct Download" customStyle={{marginBottom:'20px'}}/>
        </Link>
        <iframe
          loading="lazy"
          allowFullScreen="allowFullScreen"
          scrolling="no"
          className="fp-iframe"
          style={{ border: "none", width: "100%", height: "700px" }}
          width="100%"
          height="700"
          src="https://heyzine.com/flip-book/78b58b4cb0.html"
          title="E-Souvenir"
        ></iframe>
      </div>
    </div>
  );
}

export default Esouvenir;
