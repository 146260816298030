import React from 'react';
import Avatar from "../../assets/images/avatar.png";
import { getUserDetailsById } from '../../services/UserService';
import { toast } from 'react-toastify';
import { Grid } from "@mui/material";

const StudentCard = ({ info, openModel, viewData }) => {
  const handleClick = async () => {
    // Get user details from DB.
    try {
      const res = await getUserDetailsById(info.uni_index);
      openModel();
      viewData(res.data.data);
    } catch (error) {
      toast.error("Error : " + error.response.data.message);
    }
  }

  return (
    <div className='student-card container' onClick={handleClick}>
      <div className="card-left">
        <div className="img-container">
          <img src={info?.front_picture_link || Avatar} alt='Student Profile' />
        </div>
      </div>

      <div className="card-right">
        <div className="header-bar">
          <div className="name">{`${info?.f_name} ${info?.l_name ? info?.l_name : ""}`}</div>
          <a className="primary-btn text-decoration-none" href={info?.cv_link} rel="noreferrer" target="_blank" >View CV</a>
        </div>


        <div className="position-section">
          <div className="title">Positions Looking for:</div>
          <div className="positions">

            <Grid container spacing={1}>
              {
                (
                  typeof info?.preferred_area === 'string'
                    ? JSON.parse(info.preferred_area)
                    : info?.preferred_area || [] // Ensure fallback to an empty array if undefined or null
                ).map((position, index) => (
                  <Grid item xs="auto" key={index}>
                    <div className="position-item" key={index}>{position}</div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>

        <div className="std-description">{info?.profile}</div>

        <div className="technology-section">
          <div className="technologies">
            <Grid container spacing={1}>
              {
                // Convert info.skills to an array if it's a string, otherwise use it directly
                // Then map over the array to render tech items
                (
                  typeof info?.skills === 'string'
                    ? JSON.parse(info.skills)
                    : info?.skills || [] // Ensure fallback to an empty array if undefined or null
                ).map((tech, index) => (
                  <Grid item xs="auto" key={index}>
                    <div className="tech-item" key={index}>{tech}</div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentCard