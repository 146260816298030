import React from "react";
import mainLogo from "../../../assets/main_logo.png";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAuth } from "../../../contexts/AuthContext";
import ButtonComponent from "../../ButtonComponent/ButtonComponent";

const Header = ({url}) => {
  const navigate = useNavigate();
  const {role} = useAuth();

  const menuData = [
    { path:'/', name: "Home" },
    { path:"/companies", name: "Companies" },
    { path:"/students", name: "Students" },
    // { path:"/souvenir", name: "E-Souvenir" },
  ];

  const handleButtonClick=() => {
    if(role === "student") {
      navigate("/dashboard-student")
    }else if (role === "admin"){
      navigate("/dashboard-admin")
    }else {
      navigate("/login")
    }
  };
  return (
    <Navbar expand="lg" sticky="top" className="bg-body-tertiary navbar">
      <Container style={{paddingTop:0, paddingBottom:0}}>
        <Navbar.Brand href="/">
          <img
            src={mainLogo}
            width="146.293px"
            height="60px"
            className="d-inline-block align-top"
            alt="FIT FUTURE CAREERS Main Logo-h"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto navlink">
            {menuData.map((item) => (
              <Link to={item.path} key={item.name} className="nav-link text">
                <div className={`list_item ${item.path===url? "active-nav":""}` }>{item.name}</div>
              </Link>
            ))}
          </Nav>
          <Nav className="ms-auto">
            <ButtonComponent handleButtonClick={handleButtonClick} buttonText={role === "admin" || role ===  "student" ? "Dashboard" : "Login"}/>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
