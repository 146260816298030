import React, { useState } from 'react'
import './PasswordRest.css'
import { toast } from 'react-toastify';
import { resetPassword } from '../../services/StudentService';
import { useAuth } from '../../contexts/AuthContext';
import 'bootstrap-icons/font/bootstrap-icons.css';

const PasswordReset = () => {
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [passwordVisibility, setPasswordVisibility] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const [errors, setErrors] = useState({});
    const formErrors = {};

    const resetFormFields = () => {
        setFormData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
    };

    const togglePasswordVisibility = (fieldName) => {
        setPasswordVisibility((prevVisibility) => ({
            ...prevVisibility,
            [fieldName]: !prevVisibility[fieldName],
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const { userProfileData } = useAuth();

    const handleSubmit = async (e) => {

        e.preventDefault();
        setErrors(validatePasswordReset());
        if (Object.keys(formErrors).length === 0) {
            if (formData.newPassword === formData.confirmPassword) {
                const userObject = {
                    "index": userProfileData.uni_index,
                    "currentPassword" : formData.currentPassword, 
                    "newPassword" : formData.newPassword
                };
                await resetPassword(userObject).then((res) => {
                    toast.success('Password reset Successfully!');
                    resetFormFields();
                }).catch((err) => {
                    toast.error(err.response.data.message+'!')
                })

            } else {
              toast.error('Passwords not matched. Check Again')
            }
        } else {
            toast.error("Please check the input fields.")
        }
    };

    const validatePasswordReset = () => {
        formData.currentPassword !== "" ? delete formErrors.currentPassword : (formErrors.currentPassword = "Please enter current password!");
        formData.newPassword !== "" ? delete formErrors.newPassword : (formErrors.newPassword = "Please enter new password!");
        formData.confirmPassword !== "" ? delete formErrors.confirmPassword : (formErrors.confirmPassword = "Please re-enter new password!");
        return formErrors;
    };
    return (
        <div>
            <div className="card col-6 mx-auto">
                <div className="card-header">
                    Change Password
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3 align-items-center mb-4">
                            <div className="col-md-4">
                                <label htmlFor="currentPassword" className="col-form-label">
                                    Current Password
                                </label>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.currentPassword ? 'text' : 'password'}
                                        id="currentPassword"
                                        name="currentPassword"
                                        className="form-control form-control-resetform"
                                        aria-describedby="passwordHelpInline"
                                        value={formData.currentPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => togglePasswordVisibility('currentPassword')}
                                    >
                                        {passwordVisibility.currentPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 align-items-center mb-4">
                            <div className="col-md-4">
                                <label htmlFor="inputPassword6" className="col-form-label">New Password</label>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.newPassword ? 'text' : 'password'}
                                        id="newPassword"
                                        name="newPassword"
                                        className="form-control form-control-resetform"
                                        aria-describedby="passwordHelpInline"
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => togglePasswordVisibility('newPassword')}
                                    >
                                        {passwordVisibility.newPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 align-items-center mb-4">
                            <div className="col-md-4">
                                <label htmlFor="inputPassword6" className="col-form-label">Confirm Password</label>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className="form-control form-control-resetform"
                                        aria-describedby="passwordHelpInline"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => togglePasswordVisibility('confirmPassword')}
                                    >
                                        {passwordVisibility.confirmPassword ? (
                                            <i className="bi bi-eye-slash-fill"></i>
                                        ) : (
                                            <i className="bi bi-eye-fill"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Reset Password</button>
                    </form>
                </div>
            </div>
        </div>


    )
}

export default PasswordReset;