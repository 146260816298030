import React from "react";
import Description from '../../components/companies/description'
import WidgetLarge from '../../components/companies/widgetLarge'
import WidgetSmall from '../../components/companies/widgetSmall'
import Header from '../../components/shared/header/Header'
import Footer from '../../components/shared/footer/Footer'
import './CompaniesStyles.css';

const CommonCompanyContainer = () => {
  return (
    <div>
      <Header url={"/companies"} />
      <div className="companiesPage">
        <Description />
        <WidgetLarge />
        <WidgetSmall />
      </div>
      <Footer />
    </div>
  );
};

export default CommonCompanyContainer;
