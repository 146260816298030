import React, {useState} from 'react';
import StudentTable from "../../components/StudentTableComponent/StudentTable";
import AddStudentButton from "../../components/AddStudentComponent/AddStudentButton";
import OpenModal from '../../components/AddStudentComponent/AddStudentModal';
import './StudentPage.css';

const StudentPageAdmin = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className='container'>
      
      <AddStudentButton setShowModal={setShowModal}  />
      <OpenModal showModal={showModal} setShowModal={setShowModal} />
      <StudentTable/>
    </div>
  );
};
export default StudentPageAdmin;

