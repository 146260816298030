import React from "react";
import img1 from "../../assets/Image_1.png";
import '../../containers/HomeContainer/HomePage.css';
import img2 from "../../assets/uom.png";
import img3 from "../../assets/Intects-Logo-black.png";
import img4 from "../../assets/Faculty.png";
import img5 from "../../assets/intecs.png";
import img6 from "../../assets/intecs_techtalk.png"
import img7 from "../../assets/fit2school.png";
import img8 from "../../assets/LINKIT-FIT.png"
import RootcodeIMG from "../../assets/CompanyLogos/rootcode.png"
import IFS from "../../assets/CompanyLogos/ifs.png"
import Codegen from "../../assets/CompanyLogos/codegen.png"
import Card from './Card';
import saga from "../../assets/CompanyLogos/saga.png";
import fitmoments from "../../assets/CompanyLogos/fit-moments.png";

function Home() {
  const cardsData = [
    {
      section: 'ABOUT US',
      title: 'Faculty of Information Technology',
      text: "The Faculty of Information Technology at the University of Moratuwa was established in 2001. It offers four degree programs: a B.Sc. Hons. in Information Technology, a B.Sc. Hons. in Information Technology and Management, a B.Sc. Hons. in Artificial Intelligence, and an external degree program in Bachelor of Information Technology. The faculty has a reputation for producing successful IT graduates for the industry.",
      imageUrl: img4,
    },
    {
      section: 'OUR STORY',
      title: 'What is INTECS',
      text: "INTECS is a student society at the University of Moratuwa's Faculty of Information Technology. It was established in 2002 with the goal of promoting awareness of the field of IT among undergraduate students and providing them with opportunities to learn about the Sri Lankan IT industry. INTECS aims to enrich students' technical skills and develop their leadership, communication, and coordination abilities in order to produce skilled IT professionals for the country.",
      imageUrl: img5,
    },
  ];

  return (
    <section id="home" className="hero-section container">
      <div className="d-flex align-content-around flex-wrap mx-4 mx-md-0" style={{ minHeight: '90vh' }}>
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex flex-column h-100">
              <div className="hero-content" data-aos="fade-right">
                <h1 style={{ color: "#51127F", fontWeight: 800 }} className="wow fadeInUp" data-wow-delay=".2s">FIT FUTURE CAREERS </h1>
                <h1 style={{ color: "#D70A84", fontWeight: 800 }}>2024</h1>
                <p className="wow fadeInUp" style={{ textAlign: 'justify' }} data-wow-delay=".4s">Welcome to FIT Future Careers 2024, the premier IT Professional recruitment program conducted by the Faculty of Information Technology. This distinguished event serves as a pivotal link between leading IT organizations and a diverse pool of outstanding candidates.</p>
              </div>
              <div className="card border-0" data-aos="fade-up">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-8 col-12 mt-3 mt-sm-0 m-0 order-3 order-sm-1">
                    <h5>Organized by</h5>
                    <div className="text-card px-4">
                      <p className="fw-bold fs-4 mb-2">INTECS</p>
                      <p className="m-0">Faculty of Information Technology</p>
                      <p className="m-0">University Moratuwa</p>
                    </div>
                  </div>
                  <div className="col-sm-2 col-6 m-0 order-1 order-sm-2 text-center text-sm-start" style={{maxWidth: '8rem'}}>
                    <img alt="" className="img-fluid" src={img2} />
                  </div>
                  <div className="col-sm-2 col-6 m-0 order-2 order-sm-3 text-center text-sm-end" style={{maxWidth: '8rem'}}>
                    <img alt="" className="img-fluid" src={img3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-sm-block">
            <div data-aos="fade-left" className="hero-img wow fadeInUp h-100" data-wow-delay=".5s">
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 mx-md-0">
        <Card cardsData={cardsData} />
      </div>
      <div style={{ marginBottom: "6%" }} data-aos="fade-up" className="mx-4 mx-md-0">
        <div>
          <h4 style={{ color: "#D70A84", fontSize: '16px', fontWeight: 700, marginBottom: "2%" }}>WHAT WE DO</h4>
          <h3 style={{ color: "#51127F", fontSize: '32px', fontWeight: 700, marginBottom: "2%" }}>Programs & Social Contribution</h3>
          <p>INTECS is a student society at the University of Moratuwa's Faculty of Information Technology. It was established in 2002 with the goal of promoting awareness of the field of IT among undergraduate students and providing them with opportunities to learn about the Sri Lankan IT industry. INTECS aims to enrich students' technical skills and develop their leadership, communication, and coordination abilities in order to produce skilled IT professionals for the country.</p>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4" >
          <div className="col">
            <div className="card h-100" style={{ backgroundColor: "#F8F2FD", borderRadius: "20px", margin: "3%", border: 'none', marginTop: "8%" }}>
              <img src={img6} className="card-img-top" style={{ width: "80%", objectFit: 'cover' }} alt="..." />
              <div className="card-body" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                <h5 className="card-title" style={{ textAlign: 'center', color: "#51127F", fontWeight: 700, marginBottom: "5%", marginTop: '5%' }}>INTECS TECH TALKS</h5>
                <p className="card-text" style={{ textAlign: 'justify' }}>INTECS TechTalk is a tech talk series that is organized with the aim of being an ocean of knowledge to the undergraduates of the Faculty of Information Technology, University of Moratuwa and beyond. The tech talk series is being conducted virtually, through Zoom. So far in the year 2022, nine such Tech Talk sessions have been organized tapping into topics that the undergraduates are most interested in. Personnel from OrangeHRM, 99X Technology, BCS Sri Lanka Section, Sysco Labs, CodeGen International, Ascentic and Geveo Australasia have given their support in conducting the INTECS Tech Talk series so far in the year 2022 & 2024.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100" style={{ backgroundColor: "#F8F2FD", borderRadius: "20px", margin: "3%", border: 'none', marginTop: "8%" }}>
              <img src={img7} className="card-img-top" style={{ width: "80%", objectFit: 'cover' }} alt="..." />
              <div className="card-body" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                <h5 className="card-title" style={{ textAlign: 'center', color: "#51127F", fontWeight: 700, marginBottom: "5%", marginTop: '5%' }}>FIT2SCHOOL</h5>
                <p className="card-text" style={{ textAlign: 'justify' }}>FIT2School was a project which was launched several years back with the intention of providing assistance to the school students who take ICT as a subject for GCE O/L and A/L. This is done as a Community Service based project by INTECS. The articles are written on the Sinhala and Tamil blog sites continue to be promoted among school children who are interested in learning ICT through the INTECS Facebook page and FIT2School dedicated Facebook page (facebook.com/FIT2School). New interested writers have also got on board with INTECS in mind of giving back to the community.</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100" style={{ backgroundColor: "#F8F2FD", borderRadius: "20px", margin: "3%", border: 'none', marginTop: "8%" }}>
              <img src={img8} className="card-img-top" style={{ width: "80%", objectFit: 'cover' }} alt="..." />
              <div className="card-body" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                <h5 className="card-title" style={{ textAlign: 'center', color: "#51127F", fontWeight: 700, marginBottom: "5%", marginTop: '5%' }}>LINKIT</h5>
                <p className="card-text" style={{ textAlign: 'justify' }}>INTECS TechTalk is a tech talk series that is organized with the aim of being an ocean of knowledge to the undergraduates of the Faculty of Information Technology, University of Moratuwa and beyond. The tech talk series is being conducted virtually, through Zoom. So far in the year 2022, nine such Tech Talk sessions have been organized tapping into topics that the undergraduates are most interested in. Personnel from OrangeHRM, 99X Technology, BCS Sri Lanka Section, Sysco Labs, CodeGen International, Ascentic and Geveo Australasia have given their support in conducting the INTECS Tech Talk series so far in the year 2022 & 2024.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partners-card" data-aos="fade-right">

        <h4 style={{ color: "#D70A84", fontSize: '16px', fontWeight: 700, marginBottom: "2%" }}>SPONSORS</h4>
        <h3 style={{ color: "#51127F", fontSize: '32px', fontWeight: 700, marginBottom: "2%" }}>Meet Our Partners</h3>

        <div>
          <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: "11px" }}>
            <div className="col-12 col-md-6 text-center">
              <div className="partners-section-platinum-title mt-5 mb-4">Platinum Partner</div>
              <img alt="RootCode Logo" className="partners-platinum-image" src={RootcodeIMG} />
            </div>
          </div>
          <div className="justify-content-center col-12">
              <div className="partners-section-platinum-title mt-5 text-center">Technology Partner</div>
              <div className="text-center">
                <img alt="IFS Logo" className="partners-silver-image img-fluid" src={IFS} />
              </div>
            </div>
          <div className="row" style={{ marginBottom: "70px" }}>
            <div className="justify-content-center col-12">
              <div className="partners-section-platinum-title mt-5">Co-Partner</div>
              <div className="text-center">
                {/* <img alt="99X" className="partners-silver-image img-fluid" src={xLogo} />
                <img alt="Direct FN & Trapo Technologies" className="partners-silver-image img-fluid" src={DFNLogo} />
                <img alt="SimCentric" className="partners-silver-image img-fluid" src={simLogo} />
                <img alt="Xitricon" className="partners-silver-image img-fluid" src={XITLogo} /> */}
                <img alt="Codegen Logo" className="partners-silver-image img-fluid" src={Codegen} />
              </div>
            </div>
            <div className="justify-content-center col-12">
              {/* <div className="partners-section-platinum-title mt-5">Co-Partners</div>
              <div className="text-center">
                <img alt="BISTECH Global" className="partners-silver-image img-fluid" src={BistecLogo} />
                <img alt="OREL IT" className="partners-silver-image img-fluid" src={OralLogo} />
                <img alt="Synergen" className="partners-silver-image2 img-fluid" src={SynergenLogo} />
              </div> */}
            </div>
            {/* <div className="justify-content-center col-12">
              <div className="partners-section-platinum-title mt-5 text-center">Telecommunication Partner</div>
              <div className="text-center">
                <img alt="Dialog" className="partners-silver-image img-fluid" src={DialogLogo} />
              </div>
            </div> */}
            <div className="justify-content-center col-12">

              <div className="partners-container text-center">
                <div className="partner">
                  <div className="partners-section-platinum-title mt-5">Event Partner</div>
                  <img alt="SAGA" className="partners-silver-image img-fluid" src={saga} />
                </div>
                <div className="partner">
                  <div className="partners-section-platinum-title mt-5">Media Partner</div>
                  <img alt="FIT-Moments" className="partners-silver-image img-fluid" src={fitmoments} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;