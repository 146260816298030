import React from "react";
import'./InputField.css';

const InputField = ({ label, onChange, placeHolder,type }) => {
  return (
    <div className="d-flex justify-content-center text-center my-2 w-100 align-self-center w-100 flex-center my-auto">
      <div className="mb-3 d-flex flex-column w-100">
        <label className="form-label text-start" style={{marginBottom:'3px'}}>
          {label}
        </label>
        <input
          type={type}
          className="input-field form-control form-control-field"
          id={label}
          placeholder={placeHolder}
          onChange={onChange}

          required
        />
      </div>
    </div>
  );
};

export default InputField;
