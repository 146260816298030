import * as React from "react";
import * as ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import AuthProvider from "./contexts/AuthContext";
import ToastComponent from "./components/ToastComponent/ToastComponent";

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <ToastComponent />
        <App />
      </BrowserRouter>
    </AuthProvider>
  // </React.StrictMode>
);
