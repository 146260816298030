import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Modal, Fade, Button, TextField, IconButton, Autocomplete, Grid } from '@mui/material'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getAllSponsorTypes } from '../../services/SponsorService';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';

import storage from "../../firebaseConfig"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AddCompanyModal = ({ open, onClose, formData, setFormData, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [sponsorTypes, setSponsorTypes] = useState([]);
  const [selectedSponsorType, setSelectedSponsorType] = useState(null);
  const [companyImgName, setCompanyImgName] = useState("");
  const [loading, setLoading] = useState({
    logo: false,
    save: false
  });
  const [errors, setErrors] = useState({});
  const formErrors = {};

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateCompany());
    if (Object.keys(formErrors).length === 0) {
      onSubmit(formData);
      onClose();
    } else {
      toast.error("Please check the input fields.")
    }
  };

  const validateCompany = () => {
    const emailRegex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    formData.company_name !== "" ? delete formErrors.company_name : (formErrors.company_name = "Please enter company name!");
    formData.description !== "" ? delete formErrors.description : (formErrors.description = "Please enter company description!")
    formData.logo_link !== "" ? delete formErrors.logo_link : (formErrors.logo_link = "Please Upload the company logo!")
    formData.password !== "" ? delete formErrors.password : (formErrors.password = "Please enter password!")
    formData.sponsor_id !== "" ? delete formErrors.sponsor_id : (formErrors.sponsor_id = "Please select sponsor type!")
    formData.username !== "" ? delete formErrors.username : (formErrors.username = "Please enter username!")
    formData.email !== "" && emailRegex.test(formData.email) ? delete formErrors.email : (formErrors.email = "Please enter a valid email!");

    return formErrors;
  };

  const handleOnCancel = () => {
    setFormData(" ")
    onClose();
  }

  const handleToggleVisibility = () => setShowPassword(!showPassword);

  const handleSponsorTypeChange = (event, newValue) => {
    setSelectedSponsorType(newValue);
    setFormData({ ...formData, sponsor_id: newValue.sponsor_id })
  };

  const handleCompanyImgUpload = (event) => {
    setLoading(prevState => ({
      ...prevState,
      logo: true
    }));
    const frontImg = event.target.files[0];
    setCompanyImgName(frontImg.name);
    const storageRef = ref(storage, `/company/${formData.company_id}/${frontImg.name}`);

    const uploadTask = uploadBytesResumable(storageRef, frontImg);

    uploadTask.on(
      "state_changed",
      (snapshot) => { },
      (err) => {
        toast.error("Something went wrong")
        setLoading(prevState => ({
          ...prevState,
          logo: false
        }));
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({ ...formData, logo_link: url })
          setLoading(prevState => ({
            ...prevState,
            logo: false
          }));
        });
      }
    );
  };

  useEffect(() => {
    getAllSponsorTypes().then((res) => {
      setSponsorTypes(res.data.data)
      const initialOption = res.data.data.find(option => option.sponsor_id === formData.sponsor_id);
      if (initialOption) {
        setSelectedSponsorType(initialOption);
      }
    }).catch((err) => {
      toast.error("Something went wrong");
    })
  }, [])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Item>
                <Grid container spacing={3} alignItems="center">
                  <Grid item sm={4} md={6}>
                    <TextField value={companyImgName} fullWidth disabled
                      error={errors.logo_link}
                      helperText={errors.logo_link ? errors.logo_link : null}></TextField>
                  </Grid>
                  <Grid item sm={8} md={6}>
                    <LoadingButton component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={handleCompanyImgUpload} loading={loading.logo} loadingPosition="start" fullWidth>
                      <span>{loading.logo ? 'Uploading' : 'Upload Company Logo'}</span>
                      <VisuallyHiddenInput type="file" />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="Company Name"
                value={formData.company_name}
                onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                fullWidth
                error={errors.company_name}
                helperText={errors.company_name ? errors.company_name : null}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label="Username"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                fullWidth
                error={errors.username}
                helperText={errors.username ? errors.username : null}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleToggleVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                error={errors.password}
                helperText={errors.password ? errors.password : null}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="Email"
                value={formData.email}
                type="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                fullWidth
                error={errors.email}
                helperText={errors.email ? errors.email : null}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="Website"
                value={formData.website_link}
                onChange={(e) => setFormData({ ...formData, website_link: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <Autocomplete
                value={selectedSponsorType}
                onChange={handleSponsorTypeChange}
                options={sponsorTypes}
                getOptionLabel={(option) => option.sponsor_name}
                renderInput={(params) => (
                  <TextField {...params} label="Select the Sponsorship type"
                    error={errors.sponsor_id}
                    helperText={errors.sponsor_id ? errors.sponsor_id : null} />
                )}
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                fullWidth
                multiline
                error={errors.description}
                helperText={errors.description ? errors.description : null}
              />
            </Grid>
            <Grid item sm={12} md={9}>
              <LoadingButton type="submit" component="label" variant="contained" style={{ alignItems: 'center' }} fullWidth onClick={handleSubmit} loading={loading.save} loadingPosition="start" disabled={loading.logo}>
                <span>{loading.save ? 'Saving' : 'Save'}</span>
              </LoadingButton>
            </Grid>
            <Grid item sm={12} md={3}>
              <Button variant="contained" color='error' style={{ alignItems: 'center' }} fullWidth onClick={handleOnCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade >
    </Modal >
  );
};

export default AddCompanyModal;