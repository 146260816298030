export const getRefreshToken = () => localStorage.getItem('FFC_' + encode('refresh_token'));

export const setRefreshToken = (token) => localStorage.setItem('FFC_'+ encode('refresh_token'), token);

export const removeRefreshToken = () => {
    localStorage.removeItem('FFC_' + encode('refresh_token'));
    window.dispatchEvent(new Event('userLogout'));
}

export const getAccessToken = () => localStorage.getItem('FFC_' + encode('access_token'));

export const setAccessToken = (token) => {
    localStorage.setItem('FFC_' + encode('access_token'), token);
    window.dispatchEvent(new Event('userChange'));
};

export const removeAccessToken = () => localStorage.removeItem('FFC_' + encode('access_token'));

const encode = (token) => token.split('').map(c => c.charCodeAt(0)).map(n => (n + 10).toString(16)).join('');
