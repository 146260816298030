import React, { useState } from 'react';
import {
  InstantSearch,
  Pagination,
  SearchBox,
  Hits,
  Stats,
} from "react-instantsearch";
import StudentSearchBar from '../../components/StudentSearchBar/StudentSearchBar';
import FilterComponent from '../../components/FilterComponent/FilterComponent';
import ReactPaginate from 'react-paginate';


import { searchClient } from "../../typesenseAdapter";
import './Student.css';
import './Student.css';
import Footer from '../../components/shared/footer/Footer';
import Navbar from '../../components/shared/header/Header';
import StudentCard from '../../components/students/StudentCard';
import StudentModal from '../../components/students/StudentModal';
import CustomRefinementList from '../../components/FilterComponent/CustomRefinementList';
import { StudentsTour } from '../../components/OnboardingTours/StudentsTour';

const StudentsContainer = () => {

  const [modalShow, setModalShow] = useState(false);
  const [modelData, setModelData] = useState({
    LinkedIn_link: "",
    cv_link: "",
    email: "",
    f_name: "",
    front_picture_link: "",
    l_name: "",
    phone: "",
    preferred_area: "",
    profile: "",
    side_picture_link: "",
    skills: ""
  });

  const showModel = () => setModalShow(true);
  const hideModel = () => setModalShow(false);

  
  return (
    
    <div>

      <Navbar url={'/students'} />
      <InstantSearch indexName="students" searchClient={searchClient}>

        {/* Onboarding Tour */}
        <StudentsTour/>
        
        <div className="container add-padding">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 d-flex align-items-center justify-content-center">
              <div style={{ minWidth: '100%' }}>
                <SearchBox
                  placeholder='Search for Name, Index Number, Skills, Job Position...'
                  classNames={{
                    root: 'input-group border rounded-4 mt-4 p-1 form-color align-search-bar',
                    input: 'form-control form-control-search border-0 form-color',
                    form: 'd-flex w-100 flex-row-reverse',
                    submitIcon: 'fa fa-search icon-color h-100 w-100 fa-lg',
                    submit: 'btn btn-link text-info button-icon',
                    resetIcon: 'fa fa-times icon-color',
                    reset: 'btn btn-link text-info button-icon d-none',
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-1 d-flex align-items-center justify-content-center">
              <div style={{ minWidth: '100%', paddingTop: '30px' }}>
                <CustomRefinementList attribute="preferred_area" />
              </div>
            </div>
            <Stats style={{ paddingTop: '5px', color: "#6F8BA4" }} />
          </div>
        </div>

        <div className="align-items-center justify-content-center p-4">
          {/* Details of the search */}

          {/* Results */}
          <Hits
            className='d-flex flex-column gap-4 flex-wrap justify-content-center w-100 hits-container'
            hitComponent={({ hit }) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '32px 0' }}>
                <StudentCard
                  info={{
                    uni_index: hit.uni_index,
                    f_name: hit.f_name,
                    l_name: hit.l_name,
                    profile: hit.profile,
                    preferred_area: hit.preferred_area,
                    skills: hit.skills,
                    cv_link: hit.cv_link,
                    front_picture_link: hit.front_picture_link,
                  }}
                  openModel={showModel}
                  viewData={setModelData}
                />
              </div>
            )}
          >
          </Hits>

          <div className="student-footer">

          </div>

          <StudentModal
            show={modalShow}
            onHide={() => hideModel()}
            data={modelData}
          />
        </div>

        <Pagination
          translations={
            {
              firstPageItemText: 'First',
              previousPageItemText: 'Previous',
              nextPageItemText: 'Next',
              lastPageItemText: 'Last',
            }
          }
          classNames={
            {
              root: 'pagination',
              selectedItem: 'active',
              disabledItem: 'disabled',
              firstPageItem: 'first',
            }
          }

        />

        <Footer />
      </InstantSearch>
    </div>
  );

};

export default StudentsContainer;
