import React from "react";
import Modal from "react-bootstrap/Modal";
import phoneIcon from "../../assets/icons/phone.svg";
import emailIcon from "../../assets/icons/mail.svg";
import degreeIcon from "../../assets/icons/degree.svg";
import statusIcon from "../../assets/icons/star.svg";
import Avatar from "../../assets/images/avatar.png";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const StudentModal = (props) => {
  const info = props.data;

  const safeParseJson = (value) => {
    try {
      return typeof value === 'string' ? JSON.parse(value) : value || [];
    } catch {
      return [];
    }
  };

  // Assuming `info` is an object that might have `preferred_area` and `skills` properties
  const preferredAreaArray = safeParseJson(info?.preferred_area);
  const SkillsArray = safeParseJson(info?.skills);

  const formatLinkedInURL = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };

  return (
    <div>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="student-modal"
        animation
      >

        <Modal.Body className="student-modal-card" style={{ padding: '3rem' }}>
          <div className="card-left" style={{ marginRight: '2rem' }}>
            <div className="img-container">
              <img src={info?.side_picture_link || Avatar} alt="Student Profile" />
            </div>
          </div>

          <div className="card-right">
            <div className="header-bar">
              <div className="name">{`${info?.f_name} ${info?.l_name ? info?.l_name : ""}`}</div>
              <a className="primary-btn text-decoration-none" href={info?.cv_link} target="_blank" rel="noreferrer">View CV</a>
            </div>
            <div className="position-section">
              <div className="title">Positions Looking for:</div>
              <div className="positions">
                {preferredAreaArray.map((position, index) => (
                  <div className="position-item" key={index}>
                    {position}
                  </div>
                ))}
              </div>
            </div>

            <div className="std-description">{info?.profile}</div>

            <div className="technology-section">
              <div className="technologies">
                <Grid container spacing={1}>
                  {SkillsArray.map((tech, index) => (
                    <Grid item xs="auto" key={index}>
                      <div className="tech-item" key={index}>
                        {tech}
                      </div>
                    </Grid>
                  ))}
                 
                </Grid>
              </div>
            </div>

            <div className="contact-section">

              <div className="contact-row">
                <img src={phoneIcon} className="icon" alt="phone icon" />
                <div className="title">Contact No:</div>
                <a href={'tel:' + info?.phone} style={{ textDecoration: 'none' }}>{info?.phone}</a>
              </div>

              <div className="contact-row">
                <img src={emailIcon} className="icon" alt="email icon" />
                <div className="title">E-mail:</div>
                <div className="value">
                  <a href={'mailto:' + info?.email} style={{ textDecoration: 'none' }}>{info?.email}</a>
                </div>
              </div>

              <div className="contact-row">
                <img src={degreeIcon} className="icon" alt="degree icon" />
                <div className="title">Degree Program:</div>
                <div className="value">{info?.degree_name}</div>
              </div>

              <div className="contact-row">
                <img src={statusIcon} className="icon" alt="status icon" />
                <div className="title">Employment Status:</div>
                <div className="value">{info?.hired === 0 ? ("Available") : ("Hired")}</div>
              </div>


            </div>
            <a href={info?.LinkedIn_link && (formatLinkedInURL(info?.LinkedIn_link))} className="secondary-btn mt-3 text-decoration-none linkedin-btn" target="_blank">View LinkedIn</a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StudentModal;
