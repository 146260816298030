import React from "react";
import Rootcode from "../../assets/CompanyLogos/rootcode.png";
import "../../containers/CommonCompanyContainer/CompaniesStyles.css";

function DesignBox(props) {
  const handleRedirect = () => {
    window.location.href = props.link;
  };

  return (
    <div
      className="d-flex justify-content-center"
      onClick={handleRedirect}
      style={{
        cursor: "pointer",
        padding: "24px",
        borderRadius: "16px",
        marginBottom: "16px",
        boxShadow: "0 0 0 3px #51127F, 0 0 0 3px #D70A84",
        background: "#F8F2FD",
      }}
    >
      <div className="row ">
        <div className="col-sm-12 col-md-5 col-lg-4"  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <img
            className="companyLogoLarge"
            src={props.companyLogo}
            alt="Company Logo"
           
          />
        </div>
        <div className="col-sm-12 col-md-7 col-lg-8">
          <p className="companyName">{props.companyName}</p>
          <p className="partnership">{props.partnershipStatus}</p>
          <p className="companyDescription" style={{ textAlign: "justify" }}>
            {props.description}
          </p>
        </div>
      </div>
    </div>
  );
}

function WidgetLarge() {
  return (
    <div className="widgetLargeContainer">
      <div className="widgetLarge">
        <DesignBox
          companyLogo={Rootcode}
          companyName="Rootcode"
          partnershipStatus="Platinum Partner"
          description="Rootcode is a global organization focusing on fostering innovation and building great tech. 
          It is a trusted global company, structured with engineers, designers, and data scientists. 
          Currently, Rootcode Labs has served 29 industries, has reached 79M+ users, 
          has generated USD 212M+ revenue to customers and has 95+ employees."
          link="https://rootcodelabs.com/"
        />
      </div>
    </div>
  );
}

export default WidgetLarge;
