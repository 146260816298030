import React, { useEffect, useState } from "react";
import "./StudentTable.css"
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { deleteUser, getAllUsers,userResetPassword } from "../../services/StudentService";
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const StudentTable = () => {
  const[users, setUsers] = useState([]);

  const columns = [
    { field: 'uni_index', 
      headerName: 'Index', 
      sortable: true,
      width: 150 
    },
    {
      field: 'fullName',
      headerName: 'Student Name',
      sortable: true,
      width : 300,
      renderCell: (params) => {
        const fullName = ( ((params.row.f_name) && (params.row.l_name)) ? `${params.row.f_name} ${params.row.l_name}` : 'Not set');
        return <span>{fullName}</span>;
      },
    },
    {
      field: 'hired',
      headerName: 'IS Hired',
      sortable: true,
      width: 200,
      renderCell: (params) => {
        const Result =  (`${params.row.hired}` === 1 ? 'Yes' : 'No');
        return <span>{Result}</span>;
      },
    },
    // {
    //   field: 'update',
    //   headerName: '',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 200,
    //   renderCell: (params) => (
    //     <Button variant="contained" onClick={() => handleUpdate(params.row)}>Update</Button>
    //   ),
    // },
    {
      field: 'resetPassword',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleResetPassword(params.row)}><EditIcon />Reset Password</Button>
      ),
    },
    {
      field: 'delete',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleDelete(params.row)} style={{ backgroundColor: 'red', color: 'white' }}><DeleteIcon /> Delete</Button>
      ),
    }
  ];
  
  const handleUpdate = async(row) => {};
  
  const handleDelete = async(row) => {
    if(window.confirm(`Are you sure you want to delete user ${row.uni_index}?`)){
      await deleteUser(row.uni_index).then((res)=>{
        toast.success('User deleted Successfully!')
        setUsers(users.filter((user) => user.uni_index !== row.uni_index));
        
      }).catch((err)=>{
        toast.error('User not deleted!')
      })
    }
  };
  
  const handleResetPassword = async(row) => {
    const hashedIndexValue = await row.uni_index;
    const userObject = {
      "index": row.uni_index,
      "password" : hashedIndexValue
    };
    if (window.confirm(`Are you sure you want to reset the password?`)) {
      await userResetPassword(userObject).then((res) => {
          toast.success('User password reset Successfully!')
      }).catch((err) => {
          toast.error('User password not reset!')
      })
    }
    
  };
  
  useEffect(() => {
    getAllUsers().then((res) => {
        const userData = res.data.data;
        setUsers(userData);
        
      }).catch((err) => {
        toast.error("Something went wrong")
      })
  }, [])
  return(
    <div className="table">
    <DataGrid
      components={{ Toolbar: GridToolbar }}
      getRowId={(row) => row.uni_index}
      rows={users}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10, 20]}
    />
  </div>
  );
};

export default StudentTable;