import React from 'react';
import Button from '@mui/material/Button';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import './AddStudentButton.css'

const AddStudentButton = ({ setShowModal })=>{
    return(
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10pt' }}>
            <Button variant="contained" 
                    color='success'
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal"
                    onClick={() => setShowModal(true)}
                    startIcon={<PersonAddAlt1OutlinedIcon />}>
                Add User
            </Button>
        </div>

    );
};

export default AddStudentButton;