import React from "react";
import "./Footer.css";
import mainLogo from "../../../assets/main_logo_wt.png";
import Facebook from "../../../assets/mdi_facebook.png";
import { Container, Row, Col } from "react-bootstrap";
const Footer = () => (
  <footer className="footer-container">
    <Container>
      <Row className="contend">
        <Col className="col">
          <div className="img-background">
            <img
              src={mainLogo}
              height="100px"
              className="d-inline-block align-top"
              alt="FIT FUTURE CAREERS Main Logo-f"
            />
          </div>

          <p>© 2024 INTECS. All rights reserved.</p>
        </Col>
        <Col className="col">
          <h5 className="text-white">Contact Us</h5>
          <p>Email: intecs@itfac.mrt.ac.lk</p>
          <p>Phone: +94 71 768 5206 (Dinindu Gunathilaka, President - INTECS)</p>
        </Col>
        <Col className="col">
          <h5 className="text-white">Connect with Us</h5>
          <div className="icons">
            <a href="https://www.facebook.com/fit.future.careers">
              <img src={Facebook} alt="Facebook" />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
