import React from "react";
import Chip from "@mui/material/Chip";

const PreferedArea = ({preferedArea}) => {

  return (
    <div className="about-text">
      <h3 className="dark-color technical-skills">Prefered Area</h3>
      <div className="flex-wrap-container justified-text" >
        {preferedArea.map((skill, index) => (
          <Chip
            key={index}
            label={skill}
            sx={{
              backgroundColor: "#301934",
              color: "white",
              ':hover': {
                transition: 'transform 0.6s', 
                transform: 'scale(1.05)'
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default PreferedArea;
