import { useEffect, useMemo, useRef, useState } from 'react';
import { Typography, Tooltip, Button } from '@mui/material';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCompany, getAllCompany, updateCompany } from '../../services/CompanyService';
import AddCompanyModal from '../AddCompanyModal/ModalComponent';
import { toast } from 'react-toastify';

const CompanyTable = ({ setSelectedLink, link }) => {
    const [rowId, setRowId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const companiesRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [onAction, setOnAction] = useState(false);

    const columns = useMemo(
        () => [
            {
                field: 'company_name',
                headerName: 'Name',
                width: 230
            },
            { field: 'username', headerName: 'User Name', width: 150 },
            {
                field: 'photoURL',
                headerName: 'Logo',
                width: 100,
                renderCell: (params) =>
                    <img src={params.row.logo_link} style={{ maxWidth: '100px' }} />,
                sortable: false,
                filterable: false,
            },
            { field: 'email', headerName: 'Email', width: 200 },
            {
                field: 'description', headerName: 'Description', width: 400, minWidth: 250, renderCell: (params) => (
                    <Tooltip title={params.value}>
                        <Typography noWrap variant="body2" sx={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                            {params.value}
                        </Typography>
                    </Tooltip>
                )
            },
            {
                field: 'active',
                headerName: 'Active',
                width: 100,
                type: 'boolean',
                editable: true,
            },
            {
                field: 'Edit',
                headerName: '',
                sortable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params) => (
                    <Button variant="contained" onClick={() => handleEditClick(params.row)}> <EditIcon /> Edit</Button>
                ),
            },
            {
                field: 'delete',
                headerName: '',
                sortable: false,
                disableColumnMenu: true,
                width: 150,
                renderCell: (params) => (
                    <Button variant="contained" onClick={() => handleDeleteClick(params.row)} style={{ backgroundColor: 'red', color: 'white' }}> <DeleteIcon /> Delete</Button>
                ),
            }
        ],
        [rowId]
    );

    useEffect(() => {
        getAllCompany().then((res) => {
            companiesRef.current = res.data.data;
            setCompanies(res.data.data)
        }).catch((err) => {
            toast.error("Something went wrong");
        })
    }, [onAction])

    const handleDeleteClick = async (row) => {
        if (window.confirm(`Are you sure you want to delete ${row.company_name}?`)) {
            await deleteCompany(row.company_id).then((res) => {
                setOnAction(!onAction);
                toast.success('Company deleted Successfully!')
            }).catch((err) => {
                toast.error('Company cannot be not deleted!')
            })
        }
    };

    const handleEditClick = (row) => {
        setFormData(row)
        setOpenModal(true)
    };

    const handleSubmit = async (formData) => {
        await updateCompany(formData.company_id, formData).then((res) => {
            setOnAction(!onAction);
            toast.success('Company updated Successfully!')
        }).catch((err) => {
            toast.error('Company not updated!')
        })
    };

    const filteredCompanies = companies.filter(company => {
        if (company.company_id === 999) {
            return false;
        }

        if (company.active === undefined) {
            company.active = true;
        }

        return true;
    });


    return (
        <>
            <div className="table">
                <DataGrid
                    getRowId={(row) => row.company_id}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    columns={columns}
                    rows={filteredCompanies} // Use the filtered companies array
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    onCellEditCommit={(params) => setRowId(params.id)}
                    className="custom-data-grid"
                />
            </div>

            <AddCompanyModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                formData={formData}
                setFormData={setFormData}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default CompanyTable;