import React from 'react'
import '../../containers/CommonCompanyContainer/CompaniesStyles.css'

function description() {
  return (
    <div className='body'>
        <p className='Topic'>PARTNERS</p>
        <p className='subTopic'>Our Talent Seekers</p>
        <p className='description' style={{ textAlign: 'justify' }}>FIT Future Careers, hosted by the Faculty of Information Technology,
            stands out as a leading IT professional recruitment program, 
            attracting versatile organizations in the software industry. 
            This anticipated event provides a unique platform for companies to connect
            with a highly competitive pool of IT professionals under one roof. 
            The program streamlines the recruitment process, 
            allowing companies to conduct interviews and assessments efficiently. 
            Beyond traditional fairs, FIT Future Careers integrates workshops and expert-led sessions,
            creating a holistic environment that benefits both employers and candidates. 
            By fostering meaningful connections and contributing to professional development, 
            the program plays a pivotal role in shaping the future of the IT sector, 
            serving as a dynamic conduit for companies seeking top-tier talent.</p>      
    </div>
  )
}

export default description
