import React from "react";

const AboutMe = ({aboutMe}) => {
  return (
    <div className="about-text">
      <h3 className="dark-color">About Me</h3>
      <p className="justified-text" >
        {aboutMe}
      </p>
    </div>
  );
};

export default AboutMe;
