// Card.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Card = ({ cardsData }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div style={{ marginBottom: "12%" }}>
      {cardsData.map((card, index) => (
          <div key={index} className="card mb-3 d-flex justify-content-center align-items-center align-content-stretch flex-wrap" style={{ maxWidth: '100%', marginTop: "8%", border: 'none' }} data-aos="fade-up">
            <div className="row g-4">
              {index % 2 === 0 ? (
                <div className="col-md-4">
                  <img src={card.imageUrl} className="img-fluid rounded-4" alt="..." />
                </div>
              ) : null}

              <div className="col-md-8">
                <div className="card-body">
                  <h6 className="card-section" style={{ color: "#D70A84", textAlign: 'left', fontSize: '16px', fontWeight: 700, marginBottom: "2%" }}>{card.section}</h6>
                  <h5 className="card-title" style={{ color: "#51127F", textAlign: 'left', fontSize: '32px', fontWeight: 700, marginBottom: "2%" }}>{card.title}</h5>
                  <p className="card-text" style={{ color: "#000000", textAlign: 'justify', fontSize: '16px', fontWeight: 400 }}>{card.text}</p>

                </div>
              </div>

              {index % 2 !== 0 ? (
                <div className="col-md-4">
                  <img src={card.imageUrl} className="img-fluid rounded-4" alt="..." />
                </div>
              ) : null}
            </div>
          </div>
      ))}
    </div>
  );
};

export default Card;
