import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'

const RoleGuardedRoute = ({component, roleType}) => {
    const {role} = useAuth();
    if(role === roleType){
        return (component);
    }
  return (
    <Navigate to={'/login'} replace/>
  )
}

export default RoleGuardedRoute