import axios from "./ApiService"

export const getAllUsers = () => axios.get(`user/get-all-users`);
export const createUser = (data) => axios.post(`user/create-user`,data);
export const deleteUser = (id) => axios.delete(`user/delete-user/${id}`);
export const updateUser = (id, data) => axios.put(`user/update-user/${id}`, data);

//byAdmin
export const userResetPassword = (data) => axios.put(`user/reset-userpassword`,(data));

//byUser
export const resetPassword = (data) => axios.put(`user/reset-password`,(data));