import React from "react";
import { Avatar, Paper } from "@mui/material";

const BasicInfo = ({ basicInfo }) => {
  return (
    <div className="centered-flex">
      <Paper elevation={10} className="paper">
        <Avatar src={basicInfo.img_url} style={{ width: 270, height: 270 }} />
      </Paper>
      <div style={{ marginTop: "2rem", color: "#6F8BA4" }}>
        <h1 style={{ fontSize: '30px', fontWeight: 800 }} >{basicInfo.name}</h1>
      </div>
    </div>
  );
};

export default BasicInfo;
