import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
} from "chart.js";
import { Pie } from 'react-chartjs-2';

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title, ArcElement, Tooltip, Legend);

const PieChart = ({ labels, pieData }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Count',
        backgroundColor: [
          '#B21F00',
          '#2FDE00',
        ],
        hoverBackgroundColor: [
          '#501800',
          '#175000',
        ],
        data: pieData
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      title: {
        display: true,
        text: 'Overall Hiring Status',
      },
    },
    aspectRatio: 3,
    responsive: true,
    maintainAspectRatio: false
  };

  PieChart.propTypes = {
    labels: PropTypes.array,
    pieData: PropTypes.array
  };

  return (
    <div>
      <Pie data={data} options={options} style={{ height: '300px', width: '100%' }} />
    </div>
  );
};

export default PieChart;
